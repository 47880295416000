<template>
  <div style="padding: 20px"></div>
</template>

<script>
import { faceVerify } from "@/api/credit";
export default {
  data() {
    return {
      orderNo: "",
    };
  },
  mounted() {
    // console.log(this.$route.params.orderNo,"===this.$route.params.orderNo");
    this.orderNo = this.$route.params.orderNo;
    this.faceVerifyFun();
  },
  methods: {
    faceVerifyFun() {
      const metaInfo = window.getMetaInfo();
      // const orderNo = "H202405310197900056";
      faceVerify({ metaInfo, orderNo: this.orderNo }).then((res) => {
        console.log(res);
        if (res.code !== 10000000) {
          this.$toast(res.msgCode || '系统错误');
          return;
        }
        const { certifyUrl } = res.data;
        window.location.href = certifyUrl;
      });
    },
    getInfo() {},
    copyText() {
      // 获取输入框的元素
      const textField = document.getElementById("input");

      // 选中输入框的文本
      textField.select();
      textField.setSelectionRange(0, 99999); // 对于移动设备

      // 执行复制命令
      document.execCommand("copy");

      // 可以添加一些用户反馈，比如弹窗或者改变按钮文本
      alert("文本已复制！");
    },
  },
};
</script>

<style></style>
